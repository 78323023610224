import React from 'react'
import { StyledSubmenu } from '../themes/StyledSubmenu'

const Submenu = (props) => {
    return (
        <StyledSubmenu>
            <div className='bodySubmenu'>
                <div className='submenu' style={{display: props.show}}>
                    <p className='SubmenuOpcion'>{props.opcion}</p>
                    {/* <li className='ContenedorSelector'></li> */}
                </div>
            </div>
        </StyledSubmenu>
    )
}

export default Submenu