import { useState } from 'react'


export const useCompararObjetos = (editaData) => {

  const [datosEditados, setDatosEditados] = useState({});

  const CompararObjetos = (editData) => {

    const entries = Object.values(editData);

    const iterar = Object.values(datosEditados);

    // Iterar sobre el array utilizando forEach()
    entries.forEach(([key, values]) => {
        const iterar = Object.values(editData[key]);
        if (entries !== iterar) {
           console.log(values);
          }
        // Puedes hacer lo que necesites con cada clave y valor aquí
    });

  }


  return {
    setDatosEditados,
    datosEditados,
    CompararObjetos
  };
}