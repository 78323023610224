import styled from 'styled-components';

export const LayoutLoader = styled.div`

.loader{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.loaderImg {
  position: relative;
  width: 100px;
  transform: rotate(180deg);
}
.loader img{
    height: 85%;
    width: 85%;
}
.duo {
  height: 55px;
  width: 110px;
  background: hsla(0, 0%, 0%, 0.0);
  position: absolute;
  
}

.duo, .dot {
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.duo1 {
    right: 27px;
}

.duo2 {
  left: 20px;
}


.dot {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: absolute;
}

.dot-a {
  left: 0px;
}

.dot-b {
  right: 0px;

}


@keyframes spin {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(180deg) }
  100% { transform: rotate(180deg) }
}

@keyframes onOff {
    0% { opacity: 0; }
   49% { opacity: 0; }
   50% { opacity: 1; }
  100% { opacity: 1; }
}

.duo1 {
  animation-name: spin;
}

.duo2 {
  animation-name: spin;
  animation-direction: reverse;
}

.duo2 .dot-b {
  animation-name: onOff;
}

.duo1 .dot-a {
  opacity: 0;
  animation-name: onOff;
  animation-direction: reverse;
}
.loaderTexto{
    display: flex;
    color: #3BAF29;
    font-weight: bold;
    font-size: 22px;
    padding-top: 8px;
    letter-spacing: 1px;
}
`