import axios from 'axios';
import React, { useState } from 'react'
import { useFecha } from './useFecha';
import moment from 'moment';

const useDetailsProyect = () => {

  const { fechaActualMostrar, formatprimerDiaMes } = useFecha();

  const [loading, setLoading] = useState(true);
  const [DetallesProyecto, setDetallesProyecto]  = useState();
  const [nombreProyecto, setNombreProyecto] = useState("");
  const [descripcionProyecto, setDescripcionProyecto] = useState("");
  const [fechaInicio, setFechaInicio] = useState(fechaActualMostrar);
  const [fechaFin, setFechaFin] = useState(fechaActualMostrar);
  const [status, setStatus] = useState(0);

    // const showDetailsProyect = async (url) => {
    //     setLoading(true);
    //     // let configpost = {
    //     //   method: 'post',
    //     //   url: url,
    //     //   headers: {
    //     //     'Content-Type': 'application/json'
    //     //   }
    //     // };
    
    //     await axios.get(url)
    //     .then(response => {
    //       setLoading(false);
    //         // console.log(response.data);
    //         setNombreProyecto(response.data.nombre);
    //         setDescripcionProyecto(response.data.descripcion);
    //         setFechaInicio(moment(response.data.fechainicio).format('YYYY-MM-DD'));
    //         setFechaFin(moment(response.data.fechafin).format('YYYY-MM-DD'));
    //         setStatus(response.data.estatus);
    //         setDetallesProyecto(response.data);
            
    //       })
    //       .catch(error => {
    //         console.log(error);
    //       });
    //   }

    const showDetailsProyect = async (url) => {
      try{
        setLoading(true);
        let RespuestaServicio = await axios.get(url)

            setNombreProyecto(RespuestaServicio.data.nombre);
            setDescripcionProyecto(RespuestaServicio.data.descripcion);
            setFechaInicio(moment(RespuestaServicio.data.fechainicio).format('YYYY-MM-DD'));
            setFechaFin(moment(RespuestaServicio.data.fechafin).format('YYYY-MM-DD'));
            setStatus(RespuestaServicio.data.estatus);
            setDetallesProyecto(RespuestaServicio.data);
            setLoading(false);

      }catch(error){
        console.log(error);
      }
    }

  return {
    showDetailsProyect,
    DetallesProyecto,
    nombreProyecto,
    setNombreProyecto,
    descripcionProyecto,
    setDescripcionProyecto,
    fechaInicio,
    setFechaInicio,
    fechaFin,
    setFechaFin,
    status,
    setStatus
  }
}

export default useDetailsProyect