import React from 'react';
import imgHojaVerde from '../assets/svg/hojaverde.svg';
import imgHojaAzul from '../assets/svg/hojaazul.svg';
import { LayoutLoader } from '../themes/LayoutLoader';


const Loader = (props) => {
    return (
        <LayoutLoader>
            <div className="loader" style={{ height: props.altoContenedor }}>
                <div className="loaderImg">
                    <div className="duo duo1">
                        <div className="dot dot-a"> <img src={imgHojaVerde} /></div>
                        <div className="dot dot-b"> <img src={imgHojaAzul} /></div>
                    </div>
                    <div className="duo duo2">
                        <div className="dot dot-a"> <img src={imgHojaAzul} /></div>
                        <div className="dot dot-b"> <img src={imgHojaVerde} /></div>
                    </div>
                </div>
                <div className="loaderTexto">
                    <p>Cargando {props.title}...</p>
                </div>
            </div>
        </LayoutLoader>
    )
}

export default Loader