import styled from 'styled-components';

export const StyledFooter = styled.div`

.footerText p{
    text-align: end;
    margin: 0;
    font-weight: var(--Negritas);
    text-transform: uppercase;
}
`