import styled from 'styled-components';
const StyledModalSoporte = styled.div`

    .formularioSoporte{
        padding: 2rem;
    }

    .seccion1{
        display: flex;
    }

    .seccion2{
        display: flex;
    }

    .btn-acciones{
        display: flex;
        justify-content: flex-end;
    }
`

export { StyledModalSoporte }