import styled from 'styled-components';

export const StyledAgregar = styled.div`

.imgAdd{
    width: 70%;
}

.add{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    background: var(--AzulPKT1);
}

.actividadesCanceladas{
    background-color: lightgray;
    display: flex;
    align-items: center;
    pointer-events: none;
}

.noAgregarActividadesCanceladas{
    background-color: #666770;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    cursor: no-drop;
}

`