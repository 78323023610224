import styled from 'styled-components';
const StyledInputText = styled.div`

    .cajaTexto{
        display: flex;
        flex-direction: column;
        padding: 5px;
    }

    input{
        border: none;
        padding: 1rem;
        outline: none;
        border-radius: 5px;
    }

`

export { StyledInputText }