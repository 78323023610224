import styled from 'styled-components';
 const StyledHome = styled.div`

 .ContenedorInicio{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
 }

 .ContenedorInicio .CardWelcome{
    display: flex;
    align-items: flex-start;
 }

.ContenedorGraf{
    width: 100%;
    height: 60vh;
    padding: 2rem;
    display: flex;
    justify-content: space-around;
}

.CardGraf{
    background: white;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0px 10px 16px 0px rgba(0,0,0,0.25);
    width: 35vw;
}

.CardCancelarFacturas{
    background-color: white;
}

.CardWelcome h1, p{
    margin: 0;
    font-weight: bold;
}

.CardWelcome{
        display: flex;
        flex-direction: column;
        gap: 5px;
        background: rgb(47, 55, 104);
        border-radius: 10px;
        padding: 20px;
        box-sizing: border-box;
        background-color: #2F3768;
        color: #FFF;
}

.ContenedorFechas{
    overflow: auto;
    height: 70vh;
    display: inline-block;
    max-width: 200px; /* Ajusta el ancho según sea necesario */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fechas{
    padding: 1rem;
}

@media only screen and (min-width: 300px){

    .CardWelcome{
        width: 90vw;
    }

}

@media only screen and (min-width: 600px){

    .CardWelcome{
        width: 60vw;
    }

}

@media only screen and (min-width: 800px){

    .CardWelcome{
        width: 50vw;
    }

}

@media only screen and (min-width: 1200px){

    .CardWelcome{
        width: 30vw;
    }

}
`

export {StyledHome}