import styled from 'styled-components';
 const StyledSeguimientoActividades = styled.div`

.grupoEditar{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 10px;
    align-items: end;
    gap: 1rem;
}

.fechaSeguimiento{
    display: flex;
    flex-direction: column;
}

/* .fechaSeguimiento input{
    height: 1.2rem;
} */

.horasAplicadas{
    display: flex;
    flex-direction: column;
}

/* .horasAplicadas input{
    outline: none;
    width: 5rem;
    padding: 6px;
    box-shadow: 10px 10px 16px 0px rgba(0,0,0,0.25);
} */

.estaus{
    display: flex;
    flex-direction: column;
}

.registroActividad{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
}


.select{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: #CCCCCC 2px solid;
    height: 2rem;
    border-radius: 6px;
    width: 8rem;
    background: #FFF;
    position: relative;
    padding-left: 10px;
}

.descripcionSelect{
    font-size: small;
}

.btnSelect{
    background-color: #9597A7;
    height: 1.8rem;
    border-radius: 4px;
    width: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: #CCCCCC 2px solid;
    border-right: none;
}

.btnSelect img{
    width: 1rem;
}

.ListadoOpciones{
    position: absolute;
    background-color: #DBDDED;
    padding: 5px;
    width: 6rem;
    margin-top: 10rem;
    border-radius: 5px;
    font-size: small;
    line-height: 1rem;
}



.SelectOpciones p{
    margin:0;
}

.SelectOpciones{
    padding: 10px;
}

.SelectOpciones:hover{
    background-color: #9597A7;
    color: #FFF;
    cursor: pointer;
    border-radius: 4px;
    padding: 10px;

}

input{
    outline: none;
    padding: 10px;
    border: #CCCCCC 2px solid;
    border-radius: 5px;
    margin-right: 1rem;
}

textarea{
    outline: none;
    padding: 10px;
    border: #CCCCCC 2px solid;
    border-radius: 4px;
    margin-right: 1rem;
    resize: none;
}

.divSelect{
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    
}

.buttons{
    display: flex;
    justify-content: flex-end;
    padding: 1rem;

}

.Guardar{
    background-color: #3BAF29;
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 30%;
    color: #FFF;
    cursor: pointer;
    box-shadow: 10px 10px 16px 0px rgba(0,0,0,0.25);
}

.ContenedorFormularioSeguimiento{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media only screen and (min-width: 300px){

    .grupoEditar{
        /* flex-direction: column;
        margin-left: 18px;
        align-items: center; */
    }
}

@media only screen and (min-width: 600px){

    .grupoEditar{
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

`

export {StyledSeguimientoActividades}