import { useState } from 'react'
import Swal from 'sweetalert2'

export const useAlert = () => {

    const alertSave = (titulo) => {
        Swal.fire({
            icon: 'success',
            title: titulo
            
          })
        }
        
        const alertError = (error) => {
            Swal.fire({
                icon: 'error',
                title: error
              })
    }

    
  return {
    alertSave,
    alertError
  };
}