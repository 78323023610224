import styled from 'styled-components';

export const StyledSubmenu = styled.div`

.submenu{
    position: relative;
    background-color: var(--HeaderTabla);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.submenu:hover::before{
    position: absolute;
    content: "";
    background: var(--AzulHeader);
    width: 11rem;
    height: 2rem;
    left: 0;
    top: 10px;
    animation: SeleccionOpcion 2s ease-in-out;
}



.SubmenuOpcion {
    position: relative;
    color: var(--Blanco);
    margin: 0;
    z-index: 99;
}




@keyframes SeleccionOpcion {
  from {width: 0; height: 2rem}
  to {width: 11rem; height: 2rem}
}
`