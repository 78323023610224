import React, { useState } from 'react'
import { StyledSelectPKT1 } from '../themes/StyledSelectPKT1'

const SelectPKT1 = (props) => {
    
    return (
        <StyledSelectPKT1>
            <div className='ContenedorSelect'>
                <label className={props.CampoValidado && props.value === 0 ? "LabelNoSeleccionado" : "InputLabel"}>{props.label}</label>
                <div className={props.CampoValidado && props.value === 0 ? "NoSeleccionado" : "SelectOptions"}>
                    <select id='OpcionSeleccionada' onChange={props.Filtrado} disabled={props.desactivar} >
                        <option style={{display: props.MostrarOpcionPorDefecto}} value={props.value} >{props.OpcionPorDefecto}</option>
                            {props.MapeoOpciones?.map((opcion, index) => (
                                <option className={props.OpcionPorDefecto == opcion.descripcion && "OpcionDesactivada"} key={opcion.id} value={opcion.id} disabled={props.OpcionPorDefecto == opcion.descripcion} >{opcion.descripcion}</option>
                            ))}
                    </select>
                </div>
                {/* <span className={ props.CampoValidado && props.value === 0 ? 'FaltaSeleccionar' : "Seleccionado" }>Seleccionar {props.label}</span> */}
            </div>
        </StyledSelectPKT1>
    )
}

export default SelectPKT1