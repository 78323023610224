
export const useConvertirHora = () => {

  const showHoras = async (hora) => {
   return hora / 60
  }

  const insertHoras = async (hora) => {
   return hora * 60
  }


  return {
    showHoras,
    insertHoras
  };
}