import React, { useState } from 'react'
import { StyledAgregar } from '../themes/StyledAgregar'

const Agregar = (props) => {

    return (
        <StyledAgregar>
                <button className={props.actividadCancelada || props.estatus === 4 || props.estatus === 3 ? "noAgregarActividadesCanceladas" :'add'} onClick={props.Accion} disabled = {props.actividadCancelada || props.estatus === 4 || props.estatus === 3 ? true : false} >
                    <img className='imgAdd' src={props.IconoAgregar} alt="Agregar" />
                </button>
        </StyledAgregar>
    )
}

export default Agregar