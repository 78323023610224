import styled from 'styled-components';
const StyledModal = styled.div`

    .Btn-cerrar{
        position: absolute;
        top: 40px;
        right: 10px;
        width: 40px;
        height: 40px;
        border: none;
        background: none;
        cursor: pointer;
        transition: .3s ease all;
        border-radius: 5px;
        color: #1766DC;
    }

@media only screen and (min-width: 300px) {

    .Overlay{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.5);
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
    }

    .ContenedorModal{
        background: var(--Fondo);
        position: relative;
        border-radius: 1rem;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        width: 90vw;
    }

    

    .EncabezadoModal{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 0 0 0;
        background: var(--Fondo);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        width: 90%;
        margin: 0 auto;

        
        .TituloModal {
            background-color: rgb(21, 27, 78);
            padding: 1px;
            width: 90vw;
            padding-left: 1rem;
            text-align: start;
            border-radius: 5px;
        }

        h3{
            font-weight: 500;
            font-size: 16px;
            color: #FFF;
            
        }
    }

}
/* / Small devices (portrait tablets and large phones, 600px and up) / */
@media only screen and (min-width: 600px) {

    /* .ContenedorModal {
        width: 542px;
        min-height: 100px;
        background: var(--Fondo);
        position: relative;
        border-radius: 1rem 1rem 5px 5px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .EncabezadoModal{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 0 0 0;
        background: var(--Fondo);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        
        .TituloModal {
            background-color: rgb(21, 27, 78);
            padding: 1px;
            width: 31rem;
            padding-left: 1rem;
            text-align: start;
            border-radius: 5px;
        }

        h3{
            font-weight: 500;
            font-size: 16px;
            color: #FFF;
            
        }
    } */


}
/* / Medium devices (landscape tablets, 768px and up) / */
@media only screen and (min-width: 768px) {

    /* .ContenedorModal {
        width: 694px;
        min-height: 100px;
        background: var(--Fondo);
        position: relative;
        border-radius: 1rem 1rem 5px 5px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .EncabezadoModal{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 0 0 0;
        background: var(--Fondo);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        
        .TituloModal {
            background-color: rgb(21, 27, 78);
            padding: 1px 1px 1px 1rem;
            width: 40rem;
            text-align: start;
            border-radius: 5px;
        }

        h3{
            font-weight: 500;
            font-size: 16px;
            color: #FFF;
            
        }
    } */
}
/* / Large devices (laptops/desktops, 992px and up) / */
@media only screen and (min-width: 992px) {
    .ContenedorModal{
        width: 70vw;
    }

}
/* / Extra large devices (large laptops and desktops, 1200px and up) / */
@media only screen and (min-width: 1200px) {

    .ContenedorModal{
        width: 40vw;
    }

    /* .Overlay{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0,0,0,.5);
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .ContenedorModal{
        min-height: 100px;
        background: var(--Fondo);
        position: relative;
        border-radius: 5px;
        box-shadow: rgba(100,100,111,0.2) 0px 7px 29px 0px;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    .EncabezadoModal{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 0 0 0;
        background: var(--Fondo);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        
        .TituloModal{
            background-color: #151B4E;
            padding: 1px;
            width: 38rem;
            text-align: center;
            border-radius: 5px;
        }

        h3{
            font-weight: 500;
            font-size: 16px;
            color: #FFF;
            
        }
    } */

    


}
`

export { StyledModal }