import React from 'react';

import styled from 'styled-components';

const BotonesAcciones = (props) => {

  
const StyledBoton = styled.div`

.accion{
    border: none;
    padding: 1rem;
    border-radius: 5px;
    font-size: 20px;
    border: none;
    background: none;
    cursor: pointer;
    color: ${props.colorLetra};
    background: ${props.colorFondo};
}

.BotonDesactivado{
  border: none;
    padding: 1rem;
    border-radius: 5px;
    font-size: 20px;
    border: none;
    background: none;
    cursor: not-allowed;
    color: #FFF;
    background: var(--BotonDesactivado);
}
`


  return (
    <StyledBoton>
      <div className='contenedorBotonAccion'>
        <button className={props.desactivar ? "BotonDesactivado" :'accion'} onClick={props.clickBoton} disabled={props.desactivar} >{props.accion}</button>
      </div>
    </StyledBoton>
  )
}

export default BotonesAcciones