import React, { useState } from 'react'
import { StyledInputPKT1 } from '../themes/StyledInputPKT1'

const InputPKT1 = (props) => {

    return (
        <StyledInputPKT1>
            <div className='ContenedorInput'>
                <div className={ props.mostrar === "none" ? 'Input' :'InputBtn'}>
                    <label className={ props.CampoValidado && props.value === "" ? 'InputLabel' : 'InputLabelCamposVacios'}>{props.label}</label>
                    <input className={props.type == "search" ? 'inputSearch' : 'input' && props.CampoValidado && props.value === "" ? 'inputCamposVacios' : 'input' } type={props.type} placeholder={props.placeholder} value={props.value} onChange={props.Accion} disabled={props.desactivar} maxLength={props.size} max={props.limite} min={props.minimo} />
                    <button className='btn' style={{background: props.background, display: props.mostrar }}><img src={props.BtnImg} alt='Buscar'/></button>
                </div>
                    {/* {props.CampoValidado && props.value === "" &&
                        <span className='FaltaCampo'> Campo {props.label} requerido</span>
                    } */}
                {props.size !== undefined &&
                    <p className='ContenedorCaracteres'>{props.value.length} / {props.size}</p>
                }
            </div>
        </StyledInputPKT1>
    )
}

export default InputPKT1