import React, { useState } from 'react'
import { StyledTextAreaPKT1 } from '../themes/StyledTextArea'

const TextAreaPKT1 = (props) => {

    return (
        <StyledTextAreaPKT1>
            <div className='ContenedorTextArea'>
                <label className={props.CampoValidado && props.value === "" ? "LabelTextAreaVacio" : 'TextAreaLabel'}>{props.label}</label>
                <textarea className={props.CampoValidado && props.value === "" ? "TextareaVacio" :  'TextArea'} maxLength={props.size} value={props.value} onChange={props.onchange} placeholder={props.placeholder} disabled={props.desactivar} />
                {/* {props.CampoValidado && props.value === "" &&
                    <span className='FaltaCampo'> Campo {props.label} requerido</span>
                } */}
                <p className='ContenedorCaracteres'>{props.value.length} / {props.size}</p>
            </div>
        </StyledTextAreaPKT1>
    )
}

export default TextAreaPKT1