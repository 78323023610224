import React from 'react'
import { StyledInputText } from '../themes/StyledInputText'

const InputText = () => {
    return (
        <StyledInputText>
            <div className='cajaTexto'>
                <label>label</label>
                <input type="text" />
            </div>
        </StyledInputText>
    )
}

export default InputText