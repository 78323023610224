import moment from 'moment/moment';
import { useEffect, useState } from 'react'

export const useLocalStorage = () => {

    const [PaginaActual, setPaginaActual] = useState(localStorage.getItem("lastmenu") ? JSON.parse(localStorage.getItem("lastmenu")) : "Inicio");

    const cambioSeccion = (seccionActual) => {

        localStorage.setItem("lastmenu", JSON.stringify(seccionActual));

        setPaginaActual(localStorage.getItem("lastmenu"));
    }

    useEffect(() => {
        let nuevaSeccion = JSON.parse(localStorage.getItem('lastmenu'));
        setPaginaActual(nuevaSeccion);
    }, [cambioSeccion])
    
  return {
    cambioSeccion,
    PaginaActual
    
  };
}