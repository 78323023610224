import React from 'react';
import FondoIcono from '../assets/svg/FondoIconoPKT1Chico.svg';
import K from '../assets/svg/icono K.svg'
import { StyledNoDisponible } from '../themes/StyledNoDisponible';

const NoDisponible = () => {
  return (
    <StyledNoDisponible>
        <div className='ContenedorNoDisponible'>
            <div className='DashboardTop'>
                <img className='Wave' src={FondoIcono} alt="" />
                <span className='logo'><img src={K} alt="PKT1" /></span>
            </div>
            <div className='ContenedorTexto'>
                <span>OOPS!</span>
                <p>Parece que su dispositivo no es compatible con esta app</p>
            </div>
            <div className='ContenedorFooterNoDisponible'>
                <div className='BarraAzul'></div>
            </div>
        </div>
    </StyledNoDisponible>
  )
}

export default NoDisponible