import React from 'react'
import { StyledAgregarAct } from '../themes/StyledAgregarAct'
import flechaAbajo from "../assets/svg/abajo.svg";

const AgregarActividades = () => {
    return (
        <StyledAgregarAct>
            <div className='AgregarActividades'>
                <div className='cardForm'>
                    <div className='titulo'>
                        <h1>Agregar activdades</h1>
                    </div>
                    <div className='top'>
                        <div className='divusuario'>
                            <label>Asignar usuario</label>
                            <div className='select'>
                                <div className='contenidoSelect'>
                                    <p className='descripcionSelect'>Selccionar usuario...</p>
                                </div>
                                <div className='btnSelect'>
                                    <img src={flechaAbajo} alt="btnFlecha" />
                                </div>
                            </div>
                        </div>
                        <div className='divHorasEstimadas'>
                            <label>Horas estimadas</label>
                            <input type="number" placeholder='0' />
                        </div>
                    </div>
                    <div className='descActividad'>
                        <label>Descripcion de actividad</label>
                        <textarea name="descripcionActividad" id="idDescripcion" cols="30" rows="10" placeholder='Actividad...'></textarea>
                    </div>
                    <div className='button'>
                        <div className='divFecha'>
                            <label>Fecha</label>
                            <input type="text" disabled value="27/02/2023" />
                        </div>
                        <div className='divEstatus'>
                            <label>Estatus</label>
                            <input type="text" disabled value="Asignado" />
                        </div>
                    </div>
                    <div className='butons'>
                        <button className='Guardar'>Guardar</button>
                    </div>
                </div>
            </div>
        </StyledAgregarAct>
    )
}

export default AgregarActividades