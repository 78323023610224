import React, { useState } from 'react'
import add from '../assets/svg/add.svg'
import { StyledSoporte } from '../themes/StyledSoporte'
import Modal from '../Componentes/Modal'
import InputText from '../Componentes/InputText'
import BotonesAcciones from '../Componentes/BotonesAcciones'
import { StyledModalSoporte } from '../themes/StyledModalSoporte'

const Soportes = () => {

  const [showModal, setShowModal] = useState(false);

  const cerrarModal = () => {
    setShowModal(false);
  }

  return (
    <>
      <StyledSoporte>
        <div className='acomodoCards'>
          <div className='agregar' onClick={() => setShowModal(true)}>
            <img src={add} alt="Agregar" />
          </div>
          <div className='ListadoActividades'>
            <div className='table'>
              <div className='headerTable'>
                <p>ID</p>
                <p>Fecha de registro</p>
                <p>Departamento asignado</p>
                <p>Actividad</p>
                <p>Reporta</p>
                <p>Fecha de actividad</p>
                <p>Clasificación</p>
                <p>Estatus</p>
                <p>Acciones</p>
              </div>
              <div className='bodyTable'>

              </div>
            </div>
          </div>
        </div>
      </StyledSoporte>

      <Modal estado={showModal} cambiarEstado={() => cerrarModal()} titulo="Agregar Actividad">
        <StyledModalSoporte>
          <div className='formularioSoporte'>
            <div className='seccion1'>
              <InputText label="Asignación" placeholder="Departamento" tipo="text" />
              <InputText label="Reporta" placeholder="Usuario" tipo="text" />
              <InputText label="Clasificación" placeholder="Soporte" tipo="text" />
            </div>
            <div className='seccion1'>
              <InputText label="Actividad" placeholder="Titulo de la Actividad" tipo="text" />
              <InputText label="Fecha de asignación" placeholder="Fecha" tipo="text" />
            </div>
            <InputText label="Observación" placeholder="Comentario" tipo="text" />
            <div className='seccion2'>
              <InputText label="Origen" placeholder="Plataforma" tipo="text" />
              <InputText label="Estatus" placeholder="Estado" tipo="text" />
              <InputText label="Asignación" placeholder="Departamento" tipo="text" />
            </div>
            <div className='btn-acciones'>
              <BotonesAcciones accion={"Guardar"} colorFondo={"#3BAF29"} colorLetra={"#FFF"} colorHover={"#2a7b1d"} />
            </div>
          </div>
        </StyledModalSoporte>
      </Modal>
    </>
  )
}

export default Soportes