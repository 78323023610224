import styled from 'styled-components';

export const StyledPrivaterouter = styled.div`

.PositionLoader{
    margin-top: 16%;
    margin-bottom: 13%;
}

.global{
    overflow-y: hidden;
    background-color: var(--Blanco);
}

.globalBodyApp{
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Crea tres columnas de igual ancho */
    grid-template-rows: 2.7rem 1fr 3rem;  /* Crea dos filas de 200px de alto cada una */
    height: 100vh;
}

.appBody{
        background-color: var(--Fondo);
    }

    .header{
        grid-column: 2/3;
        grid-row: 1;
    }

    .footerPrincipal{
        grid-column: 1/3;
        grid-row: 3;
        background: var(--Blanco);
    }

    .childrenBody{
        width: 100%;
        height: 100%;
    }

    .footerPrincipal{
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 0 1%;
    }

@media only screen and (min-width: 0px){
    .global{
        display: none;
    }

    .ContenedorComponenteNoDisponible{
        display: block;
    }

}

@media only screen and (min-width: 300px) {

    .global{
        display: block;
    }

    .ContenedorComponenteNoDisponible{
        display: none;
    }

    .ContenedorMovilTop{
        display: flex;
    }

    .globalBodyApp{
        display: grid;
        grid-template-columns: 2rem 1fr; /* Crea tres columnas de igual ancho */
        grid-template-rows: 2.7rem 1fr 3rem;  /* Crea dos filas de 200px de alto cada una */
        height: 100vh;
    }

    .header{
        grid-column: 2/3;
    }

    .appBody{
        background-color: var(--Fondo);
        grid-column: 1/3;
        grid-row: 1/ 3;
    }

    .btnMostrarMenu{
        border: none;
        background: var(--AzulHeader);
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        height: 2.8rem;
    }

    .sidebar{
        background-color: var(--Blanco);
        position: absolute;
        z-index: 99;
    }

    .open{
        background-color: var(--Blanco);
        position: absolute;
        z-index: 99;
        transform: translate(2px);
        transition: 1s;
        top: 0;
        bottom: 0;
        left: -2px;
    }

    .close{
        position: absolute;
        transform: translate(-294px);
        transition: 2s;
        z-index: 99;
        top: 0;
        bottom: 0;
    }

    .appBody{
        background-color: var(--Fondo);
    }

    .children{
        padding: 1rem;
    }
    
}
/* / Small devices (portrait tablets and large phones, 600px and up) / */
@media only screen and (min-width: 600px) {

    .btnMostrarMenu{
        display: none;
    }

    .sidebar{
        display: contents;
        grid-column: 1/2;
        grid-row: 2;

    }

    .appBody{
        background-color: var(--Fondo);
        grid-column: 2/3;
        grid-row: 2;
    }

    .footerPrincipal{
        grid-column: 1/3;
        grid-row: 3;
    }

    .globalBodyApp{
        display: grid; 
        grid-template-columns: 1fr 13fr;
        gap: 0px 0px; 
    }

    .close{
        transform: translate(2px, -7px);;
    }

    
}

@media only screen and (min-width: 1200px){
    
    .children{
        padding: 2rem;
    }

}
`