import { useState } from 'react'
import axios from 'axios'
import { useShowDataTable } from './useShowDataTable';

export const usePaginador = (url) => {

  const [pagina, setPagina] = useState(1);
  const [ultimaPagina, setUltimaPagina] = useState();

  const prevPagina = () => {
    if (pagina > 1 && pagina < ultimaPagina) {
      setPagina(pagina - 1);
    }
  }

  const obtenerUltimaPagina = (url) => {
    axios.get(url)
      .then(response => {
        let respuestaNoTotalPaginas = response.data.totalPaginas;
        if(respuestaNoTotalPaginas == undefined){
          setUltimaPagina(1);
        } else{
          if(response.data.totalPaginas!=1){
            setUltimaPagina(response.data.totalPaginas);
          }else{
            setUltimaPagina(1);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const obtenerDatos = (url) => {
    axios.get(url)
      .then(response => {
        setUltimaPagina(response.data.result);
      })
      .catch(error => {
        console.log(error);
      });
  }

  return {
    pagina,
    ultimaPagina,
    obtenerUltimaPagina,
    obtenerDatos,
    prevPagina
  };
}