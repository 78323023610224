import styled from 'styled-components';
const StyledCard = styled.div`

    .card{
        margin: auto;
        background: white;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 10px;
        box-shadow: rgb(149, 151, 167) 1px 3px 12px;

    }

    .cardTitle{
        display: flex;
        justify-content: center;
    }

    .sintaTitle{
        background: #151B4E;
        color: #FFF;
        padding: 5px 10px;
        border-radius: 1rem;
    }

    h3{
        margin: 0;
    }

`

export { StyledCard }