import { useState } from 'react'
import axios from 'axios'
import { useAlert } from './useAlert';
import useDetailsProyect from './useDetailsProyect';
import { useShowDataTable } from './useShowDataTable';

export const useGuardarProyectos = () => {

    const [data, setData] = useState(null);

    const { alertSave, alertError } = useAlert();

    const [showModalAgregar, setShowModalAgregar] = useState(false);

    const { showDetailsProyect, DetallesProyecto, nombreProyecto, setNombreProyecto, descripcionProyecto, setDescripcionProyecto } = useDetailsProyect();




    // const guardarProyectos = async (url,data) => {
    //   // console.log(data);
    //   await axios.post(url, data)
    //   .then(response => {
    //     // console.log(response);
    //     setData(response.data);
    //     alertSave("Proyecto agregado", response.data);
    //     setNombreProyecto("");
    //     setDescripcionProyecto("");
    //     setShowModalAgregar(false);
    //   })
    //   .catch(error => {
    //     console.log(error.request);
    //     alertError(error.request.response);
    //   });
    // }

    const guardarProyectos = async (url,data) => {

      try {
        let RespuestaServicio = await axios.post(url, data)
        // console.log(response);
        setData(RespuestaServicio.data);
        alertSave("Proyecto agregado", RespuestaServicio.data);
        setNombreProyecto("");
        setDescripcionProyecto("");
        setShowModalAgregar(false);
      } catch (error) {
        console.log(error.request);
        alertError(error.request.response);
      }
    }

    // const guardarCambiosProyectos = async (url,data) => {
    //   console.log(data);
    //   await axios.put(url, data)
    //   .then(response => {
    //     setData(response.data);
    //     alertSave("Proyecto actualizado");
    //     setNombreProyecto("");
    //     setDescripcionProyecto("");
    //     setShowModalAgregar(false);

    //   })
    //   .catch(error => {
    //     console.log(error.request.response);
    //     alertError(error.request.response);
    //   });
    // }


    const guardarCambiosProyectos = async (url,data) => {

      try {
        let RespuestaServicio = await axios.put(url, data);
        setData(RespuestaServicio.data);
        alertSave("Proyecto actualizado");
        setNombreProyecto("");
        setDescripcionProyecto("");
        setShowModalAgregar(false);
        
      } catch (error) {
        // console.log(error.request.response);
        alertError(error.request.response);
      }
    }

    
  return {
    guardarProyectos,
    guardarCambiosProyectos,
    showModalAgregar,
    setShowModalAgregar
  };
}