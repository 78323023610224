import { useEffect, useState } from 'react';
import './App.css';
import { AuthProvider } from './Context/Context';
import Navigation from './Router/Navigation';

function App() {
  
  const [nombre, setuser] = useState('OscarOsorio');
//   useEffect(() => {
//     localStorage.setItem( 'user', JSON.stringify(user) );
// }, [user])

  return (
    <AuthProvider value={{nombre}}>
    <Navigation></Navigation>
  </AuthProvider>
  );
}

export default App;
