import React from 'react'
import { StyledSeguimientoActividades } from '../themes/StyledSeguimientoActividades'

const SeguimientoActividades = () => {
    return (
        <StyledSeguimientoActividades>
            <div className='modalSeguimiento'>
                <div className='card'>
                    <div className='headerCard'>
                        <h2>Cancelar factura LMM 1234</h2>
                    </div>
                    <form>
                        <div className='grupoEditar'>
                            <div className='fechaSeguimiento'>
                                <label>Fecha de Seguimiento</label>
                                <select name="selectFechaSeguimiento" id="idFechaSeguimiento"></select>
                            </div>

                            <div className='horasAplicadas'>
                                <label>Horas aplicadas</label>
                                <input type="number" placeholder='0' />
                            </div>

                            <div className='estaus'>
                                <label>Estatus</label>
                                <select name="selectEstatus" id="idEstatus"></select>
                            </div>

                        </div>

                        <div className='registroActividad'>
                            <label>Registro de actividades</label>
                            <textarea name="textRegistroActividad" id="idRegistroActividad" cols="30" rows="10"></textarea>
                        </div>
                        <div className='buttons'>
                            <button className='Guardar'>Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </StyledSeguimientoActividades>
    )
}

export default SeguimientoActividades