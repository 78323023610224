import styled from 'styled-components';
const StyledSoporte = styled.div`

    .acomodoCards{
        padding: 2rem;
        height: 76.2vh;
    }

    .agregar{
        display: flex;
        flex-direction: row;
        -webkit-box-pack: end;
        justify-content: flex-end;
    }

    img{
        width: 4rem;
    }

    .ListadoAsignados{
        padding: 1rem;
    }

    .ListadoActividades{
        padding: 1rem;
    }

    li{
        list-style: none;
        line-height: 3rem;
        padding-left: 5px;
    }

    li:hover{
        background-color: lightgray;
        cursor: pointer;
    }

    .table{
        width: 100%;
    }

    .headerTable{
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        border: #666770 2px solid;
        background-color: #9597A7;
        background-size: auto;
        color: #ffffff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        text-align: center;
        align-items: center;
        font-weight: bold;
    }

    .bodyTable{
        background: #FFF;
        border: #666770 2px solid;
        border-top: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

`

export { StyledSoporte }