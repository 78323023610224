import { useState } from 'react'
import axios from 'axios'

export const useShowProyectos = (url) => {

  const [mostrarProyectos, setMostrarProyectos] = useState();
  const [error, setError] = useState([]);
  
  const [showSelect, setShowSelect] = useState(false);
  const [Pagina, setPagina] = useState();
  const [TotalPaginas, setTotalPaginas] = useState();
  const [TotalRegistros, setTotalRegistros] = useState();
  const [FormularioProyectos, setFormularioProyectos] = useState();

  const [loading, setLoading] = useState(true);


   //formulario de registro de proyectos
   

  const showDatosPoryectos = async (url, filtros) => {
    setLoading(true);
    let configpost = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: filtros
    };

    await axios(configpost)
    .then(response => {

      setLoading(false);
      
      // console.log(response.data);
      let RespuestaServicio = response.data.resultado;
  
      const NuevaRespuesta = RespuestaServicio.map(resp => {
        const RehacerObjeto = { "id": resp.id, "descripcion":resp.nombre }
        return RehacerObjeto
      })
          setFormularioProyectos(NuevaRespuesta);
          setMostrarProyectos(response.data.resultado);
          setPagina(response.data.paginaActual);
          setTotalPaginas(response.data.totalPaginas);
          setTotalRegistros(response.data.registrosPorPagina);
    })
      .catch(error => {
        setError(error);
      });
  }


  const MostrarEstatusProyectos = (estatusProyecto) => {
    
    if( estatusProyecto === 0 ){

      return "Creado";

    }else if( estatusProyecto === 1 ){

      return "Proceso";

    }else if( estatusProyecto === 2 ){

      return "Terminado";

    }else if( estatusProyecto === 3 ){

      return "Cancelado";

    }else if( estatusProyecto === 4 ){

      return "Pospuesto";

    }
  }

  


  return {
    mostrarProyectos,
    showDatosPoryectos,
    MostrarEstatusProyectos,
    showSelect,
    setShowSelect,
    loading,
    setLoading,
    TotalPaginas,
    TotalRegistros,
    Pagina,
    setPagina,
    FormularioProyectos
  };
}