import styled from 'styled-components';
const StyledAgregarAct = styled.div`


    .Guardar{
        background-color: #3BAF29;
    }

    .Cancelar{
        background-color: #D60000;
    }

    .error{
        border: red 2px solid;
    }

    .ok{
        border: #3BAF29 2px solid;
    }


@media only screen and (min-width: 300px) {

    .cardForm{
        padding: 1rem 2rem;
    }

    .BodyForm{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .ContenedorEncabezadoFormulario{
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
    }

    .ContenedorFooterFormulario{
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        flex-wrap: wrap;
        width: 100%;
    }


    .butons{
        display: flex;
        justify-content: end;
    }

    .divFecha{
        width: 100%;
    }

    .ContenedorInputEstatus{
        width: 100%;
    }

    .ContenedorEncabezadoFormulario div:nth-child(1) {
        flex: 3;
    }
    .ContenedorEncabezadoFormulario div:nth-child(2) {
        flex: 3;
    }
    .ContenedorEncabezadoFormulario div:nth-child(3) {
        flex: 5;
    }

    

}
/* / Small devices (portrait tablets and large phones, 600px and up) / */
@media only screen and (min-width: 600px) {

    .ContenedorFooterFormulario{
        flex-wrap: nowrap;
    }

}
/* / Medium devices (landscape tablets, 768px and up) / */
@media only screen and (min-width: 768px) {
    
    
}
/* / Large devices (laptops/desktops, 992px and up) / */
@media only screen and (min-width: 992px) {

    /* .ContenedorEncabezadoFormulario{
        flex-wrap: nowrap;
    } */
    

}
/* / Extra large devices (large laptops and desktops, 1200px and up) / */
@media only screen and (min-width: 1200px) {

    .divFecha{
        width: 30%;
    }   
}

`

export { StyledAgregarAct }