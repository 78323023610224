import styled from 'styled-components';
export const LayoutcontainerLogin = styled.div`

    .btnIngresar button {
        background-color: var(--VerdePKT1);
        color: var(--Blanco);
        border-radius: 7px;
        border: none;
        filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 4px 4px);
        cursor: pointer;
        font-weight: var(--Negritas);
        font-size: var(--ParrafoMovil);
        margin: auto;
        padding: 10px 20px;
    }

    .contenedor-datos input {
        width: 90%;
        display: block;
        margin: auto;
        height: 7%;
        background: transparent;
        border-width: initial;
        border-style: none none solid;
        border-color: initial;
        border-image: initial;
        border-radius: 7px;
        padding-top: 10px;
        color: var(--Blanco);
        padding-left: 10px;
    }

    span{
        padding-right: 10px;
    }

    .login-principal {
        height: 100vh;
    }

    @media only screen and (min-width: 300px) {

        .login-principal {
            display: flex;
            justify-content: center;
            align-items: center;
            background-size:cover !important;
            background-repeat: no-repeat !important;
            width: 26.9rem;
            height: 56.9rem;
        }

        .ImgEscritorio{
            display: none;
        }

        .ImgCelular{
            width: 25rem;
        }

        .contendor-login {
            display: flex;
            flex-direction: column-reverse;
        }

        .contenedor-datos {
            width: 368px;
            height: 430px;
            background: rgba(255, 255, 255, 0.32);
            border-radius: 0px 0px 10px 10px;
            color: var(--Blanco);
            padding: 1rem;
            backdrop-filter: blur(5px);
            margin-top: -8px;
        }

        .cuerpoformulario h1 {
            text-shadow: rgb(70, 73, 71) 0.1em 0.1em 0.2em;
            width: 100%;
            display: flex;
            margin: auto;
            padding: 20px 0px 0px;
            color: var(--blanco);
            font-size: var(--TituloPrincipal);
        }

        .contenedor-datos h3 {
            font-size: 18px;
            margin-top: 32px;
        }

        .cuerpodeformulario h2{
            display: flex;
            align-items: center;
            color: var(--Blanco);
            font-size: var(--ParrafoMovil);
        }

        .cuerpoformulario {
            width: 90%;
            margin: auto;
        }

        .btnIngresar {
            width: 100%;
            margin-top: 33px;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            /* margin-bottom: 33px; */
        }
    }

    /* / Small devices (portrait tablets and large phones, 600px and up) / */
    @media only screen and (min-width: 600px) {

        .login-principal {
            display: flex;
            justify-content: center;
            align-items: center;
            background-size:cover !important;
            background-repeat: no-repeat !important;
            background-position: center center !important;
            width: 37.5rem;
            height: 39.7rem;
        }

        .contenedor-datos h2 {
            font-size: 18px;
            padding-left: 40px;
        }

        .contenedor-datos h3 {
            font-size: 18px;
            margin-top: 32px;
        }

        .contenedor-datos {
            display: flex;
            flex-direction: column;
        }

        .ImgCelular{
            width: 18rem;
        }


    }
    /* / Medium devices (landscape tablets, 768px and up) / */
    @media only screen and (min-width: 768px) {

        .login-principal {
            display: flex;
            justify-content: center;
            align-items: center;
            background-size:cover !important;
            background-repeat: no-repeat !important;
            background-position: center center !important;
            width: 48rem;
            height: 39.7rem;
        }

        .ImgCelular{
            display: none;
        }

        .ImgEscritorio{
            display: block;
            width: 20rem;
            height: 25.45rem;
            margin-top: -7px;
        }

        .contendor-login {
            display: flex;
            flex-direction: row;
        }

        .contenedor-datos {
            width: 255px;
            height: 376px;
            background: rgba(255, 255, 255, 0.32);
            border-radius: 10px 0px 0px 10px;
            color: #fff;
            padding: 1rem;
            backdrop-filter: blur(5px);
            margin-top: -8px;
        }

        .cuerpodeformulario {
            width: 90%;
            margin: auto;
        }

    }
    /* / Large devices (laptops/desktops, 992px and up) / */
    @media only screen and (min-width: 992px) {

        .login-principal {
            display: flex;
            justify-content: center;
            align-items: center;
            background-size:cover !important;
            background-repeat: no-repeat !important;
            background-position: center center !important;
            width: 65rem;
            height: 39.7rem;
        }

        .contenedor-datos {
            width: 90%;
        }

        .cuerpoformulario{
            width: 100%;
            margin: auto;
            padding-left: 10px;
        }

        .formulario-login{
            margin-top: 0px;
            width: 390px;
            display: flex;
            border-radius: 20px 0px 0px 20px;
        }

        .cuerpoformulario h1 {
            padding: 0px 0px 20px;
        }

        .btnIngresar{
            justify-content: flex-end;
            padding-right: 15px;
        }

    }
    /* / Extra large devices (large laptops and desktops, 1200px and up) / */
    @media only screen and (min-width: 1200px) {

        .login-principal {
            display: flex;
            justify-content: center;
            align-items: center;
            background-size:cover !important;
            background-repeat: no-repeat !important;
            background-position: center center !important;
            height: 100vh;
            width: 100%;
        }

        .contendor-login {
            display: flex;
            flex-direction: row;
        }

        .contenedor-datos {
            margin-top: 1rem;
            background: rgba(255, 255, 255, 0.32);
            border-radius: 10px 0px 0px 10px;
            color: #fff;
            backdrop-filter: blur(5px);
            height: 518px;
            margin-left: 6px;
        }
        
        
        .contenedor-img{
            position: relative;
            padding-top: 23px;

        }
        .contenedor-img img {
            height: 551px;
            width: 430px;
        }

        

        .contenedor-datos h2 {
            font-size: var(--ParrafoEscritorio);
            padding-left: 40px;
        }

        .contenedor-datos h2:nth-of-type(1) {
            margin-top: 50px;
        }


        .contenedor-datos h3 {
            font-size: 20px;
            padding-left: 40px;
            margin-top: 32px;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            background: none !important;
            border-color: #fff;
            color: #fff !important;
            padding-left: 10px;
        }

        input:focus-visible {
            outline-offset: none !important;
        }

        input:focus-visible {
            outline: none !important;
            outline-offset: none !important;
        }

        input:-webkit-autofill {
            -webkit-text-fill-color: #fff !important;
        }


        .contenedor-datos img {
            height: 20px;
            padding-right: 10px;
        }

        .ImgCelular{
                display: none;
        }
    }

`


const StyledTable = styled.div`
    .tablezone{
    color: #666770;
    font-weight: 400;
    font-size: 15px;
    border-width: 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 90%;
    margin: auto;
    border-spacing: 0px;
    }
    .containerTablaNuevaZona th {
    color: #666770;
    font-weight: 400;
    font-size: 15px;
    border-bottom: solid;
    border-color: #9597A7;
    border-bottom-style: dashed;
    border-width: 1px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.containerverde {
    color: #151B4E;
    width: 240px;
    text-align: center;
    align-items: center;
    padding-top: 17px;
    padding-bottom: 15px;
    font-weight:bold;
    font-size: 19px;
    border-bottom: solid;
    border-bottom-color: #3BAF29;
    border-width: 2px;

}

.main {
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 20px;
    margin-left: 28px;
    margin-top: 3px;
}
  .containerccheck{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 35px;

  }
/* Ocultar el input default */
input[type=checkbox] {
    visibility: hidden;
}
  
/* Empezamos a creear checkbox custom    */
.geekmark {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    height: 30px;
    width: 30px;
    background-color: #9597A7;
}
  
/* Specify the background color to be
shown when hovering over checkbox */
.main:hover input ~ .geekmark {
    background-color: #9597A7;
}
  
/* Specify the background color to be
shown when checkbox is active */
.main input:active ~ .geekmark {
    background-color: #3BAF29;
}
  
/* Specify the background color to be
shown when checkbox is checked */
.main input:checked ~ .geekmark {
    background-color: #3BAF29;
}
  
/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Display checkmark when checked */
.main input:checked ~ .geekmark:after {
    display: block;
}
  
/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.main .geekmark:after {
    left: 10px;
    bottom: 10px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
} 
.containerverde:nth-of-type(1){
    border-bottom-left-radius: 20px;
}
.containerverde:nth-of-type(3){
    border-bottom-right-radius: 20px ;
}



`