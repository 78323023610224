import React from 'react'
import { StyledModal } from '../themes/StyledModal'
import cerrar from '../assets/svg/x.svg'

const Modal = ({ children, estado, cambiarEstado, titulo }) => {

    return (
        <>
            {estado &&
                <StyledModal>
                    <div className='Overlay'>
                        <div className='ContenedorModal'>
                            <div className='EncabezadoModal'>
                                <div className='TituloModal'>
                                    <h3>{titulo}</h3>
                                    <button className='Btn-cerrar'><img src={cerrar} alt="X" onClick={cambiarEstado} /></button>
                                </div>
                            </div>
                            {children}
                        </div>
                    </div>
                </StyledModal>
            }
        </>
    )
}

export default Modal