import { useState } from "react"

/*
  Explicacion del componente

  Este componente se encarga de validar los campos del formulario,
  para consumir este componente se hace de la siguiente manera:

  Dentro de la pagina donde ocupas hacer la validacion

  ocupas importarlo dentro de la pagina

  lo mandas llamar con const { validadorObjetos, validadorDatos } = useValidacion();

  validadorObjetos: hace referencia a la funcion dentro del hook
  ValidadorDatos: hace referencia a los datos que ocupas validar

  para mandarlo llamar en donde lo ocupes puedes hacerlo con el siguiente ejemplo:

  validadorObjetos(ValidadorDatosProyecto)

  ValidadorDatosProyecto: es un objeto que contiene todos los campos que se ocupa validar

  Para la validacion de cada campo del formulario en el componente del mismo hay un campo que se encarga de eso
  que se llama CampoValidado

  Ejemplo:

  CampoValidado={VerificadorCampos.nombre}

  Lo que va entre llaves es el estado de tipo objeto que devuelve este hook y unicamente vamos a hacer referencia
  al campo correspondiente

*/

export const useValidacion = () => {

  //Aqui se declara un estado de objeto vacio para posteriormente llenarlo con las propiedades el objeto que se va recibir
  const [VerificadorCampos, setVerificadorCampos] = useState({});


    const validadorObjetos = (ValidadorDatos) => {

      let ObjetoValidador = {};

      //Aqui hace una iteracion al objeto que recibe la funcion
      for (let propiedad in ValidadorDatos) {
        //Aqui hace la validacion para ver si la propíedad del objeto viene vacia
        if (ValidadorDatos[propiedad] === "" || ValidadorDatos[propiedad] === 0) {
          //Aqui se convierte cada propiedad del objeto recibido en booleano
            ObjetoValidador = {
                [propiedad]: true
            }
            //Aqui actualizamos el valor del estado
            VerificadorCampos[propiedad] = ObjetoValidador[propiedad]
            setVerificadorCampos({ ...VerificadorCampos, [propiedad]: true })
        } 
        else {
          //Aqui hacemos lo mismo que arriba solo que con el valor contrario
            ObjetoValidador = {
                [propiedad]: false
            }
            VerificadorCampos[propiedad] = ObjetoValidador[propiedad]
            setVerificadorCampos({ ...VerificadorCampos, [propiedad]: false })
        }
      }

    }

    const LimpiadorObjetoValidador = () => {
      for (let propiedad in VerificadorCampos) {
        setVerificadorCampos({ [propiedad]: false });
      }
    }

  return {
    validadorObjetos,
    VerificadorCampos,
    LimpiadorObjetoValidador
  }

}