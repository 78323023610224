import React from 'react'
import { StyledCard } from '../themes/StyledCard'

const Card = ({children, titulo}) => {
  return (
    <StyledCard>
        <div className='card'>
          <div className='cardTitle'>
            <div className='sintaTitle'>
              <h3>{titulo}</h3>
            </div>
          </div>
          <div className='cardBody'>
            {children}
          </div>
        </div>
    </StyledCard>
  )
}

export default Card