import { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2';
import { useAlert } from './useAlert';

export const useEliminar = () => {

    const { alertErrorSave } = useAlert();


    // const cancelActivity = async(url) => {
    //   await axios.put(url)
    //   .then(response => {
    //     Swal.fire(
    //         `Actividad Cancelada`,
    //         'success'
    //     )
    //     // console.log(response);
    //   })
    //   .catch(error => {
    //     console.log(error.request.response);
    //     alertErrorSave(error.request.response);
    //   });
    // }


    const cancelActivity = async(url) => {

      try{
        let RespuestaServicio = await axios.put(url);

        Swal.fire(
          `Actividad Cancelada`,
          'success'
        )
      }catch(error){
        console.log(error.request.response);
        alertErrorSave(error.request.response);
      }
    }

    
  return {
    cancelActivity
  };
}