

export const useShowNameStatus = () => {


    const mostrarEstatus = (estatus) => {
        if (estatus === 0) {
            return ("Asignado");
        } else if (estatus === 1) {
            return ("En proceso");
        } else if (estatus === 2) {
            return ("Pendiente");
        } else if (estatus === 3) {
            return ("Terminado");
        } else if (estatus === 4) {
            return ("Cancelado");
        }
    }



    
  return {
    mostrarEstatus
  };
}