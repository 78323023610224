import styled from 'styled-components';


export const StyledNoDisponible = styled.div`

    .ContenedorNoDisponible{
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
    }

    .logo{
        position: relative;
        display: flex;
        top: 10px;
        left: 15px;
    }

    .logo img{
        width: 2.5rem;
        z-index: 99;
    }

    .DashboardTop{
        position: relative;
        list-style: none;
        width: 100%;
    }

    .Wave{
        position: absolute;
        left: 0;
        top: 0;
        height: 5rem;
    }

    .ContenedorFooterNoDisponible{
        width: 100%;
        height: 1rem;
        background: var(--VerdePKT1);
    }

    .BarraAzul{
        height: 1rem;
        background: var(--AzulPKT1);
        width: 80%;
    }

    .ContenedorTexto{
        font-size: 3rem;
    }

    span{
        font-weight: var(--Negritas);
        color: var(--VerdePKT1);
    }

    p{
        color: var(--AzulPKT1);
        margin: 0;
    }

`