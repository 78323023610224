import styled from 'styled-components';
 const StyledPaginador = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
width: 99%;
margin: auto;

.paginador{
    display: flex;
    justify-content: flex-end;
    gap: 1px;
}

.paginas{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    padding: 3px 10px;
    height: 30px;
    background: rgb(255, 255, 255);
    border: 2px solid gray;
    border-radius: 15px;
    color: rgb(149, 151, 167);
    font-size: 20px;
    font-weight: bold;
    gap: 5px;
}

.fondoStandar{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    padding: 3px;
    height: 30px;
    min-width: 30px;
    width: 30px;
    background: rgb(255, 255, 255);
    border: 2px solid gray;
    border-radius: 15px;
}

.PaginadorDesactivado{
    background: var(--BotonDesactivado);
    cursor: no-drop;
}

.flechaCon{
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 300px) {

    /* .paginas{
        width: 3rem;
    }

    .fondoStandar img{
        width: 1rem;
    }

    .fondoStandar{
        padding: 2px;
    }

    .flechaInicio{
        width: 1.5rem;
        padding: 5px;
    }
    
    .flechaFinal{
        width: 1.5rem;
        padding: 5px;
    } */

}
/* / Small devices (portrait tablets and large phones, 600px and up) / */
@media only screen and (min-width: 600px) {
}
/* / Medium devices (landscape tablets, 768px and up) / */
@media only screen and (min-width: 768px) {
}
/* / Large devices (laptops/desktops, 992px and up) / */
@media only screen and (min-width: 992px) {

}
/* / Extra large devices (large laptops and desktops, 1200px and up) / */
@media only screen and (min-width: 1200px) {


}

`

export {StyledPaginador}

