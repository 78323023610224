import moment from 'moment/moment';
import { useState } from 'react'

export const useFecha = () => {

  const formatoFechaBD = 'YYYY-MM-DD';
  const formatoMostrarFecha = 'DD/MM/YYYY';
  const formatoPrimerMes = "YYYY-MM-01";
  const formatoMostrarPrimerDiaMes = "01/MM/YYYY";

    const formatFecha = (fecha) => {
      return moment(fecha).format(formatoFechaBD);
    }

    const formatInsertFecha = (fecha) => {
      return moment(fecha, 'YYYY-MM-DD').format(formatoFechaBD);
    }

    const formatEditFecha = (fecha) => {
      return moment(fecha, 'YYYY-MM-DD').format(formatoFechaBD);
    }

    const formatShowFecha = (fecha) => {
      return moment(fecha, 'YYYY-MM-DD').format(formatoMostrarFecha);
    }

    const formatShowDefaultFecha = (fecha) => {
      return moment(fecha, 'YYYY-MM-DD').format(formatoMostrarFecha);
    }

    const primerDiaMes = (fecha) =>{
      return moment(fecha).format(formatoPrimerMes);
    }

    const formatprimerDiaMes = (fecha) =>{
      return moment(fecha, 'YYYY-MM-DD').format(formatoMostrarPrimerDiaMes);
    }

    //configuracion de fecha
    // const fechaActual = new Date();
    // const anio = fechaActual.getFullYear();
    // const mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0"); // mes es un número de 0 a 11, por lo que sumamos 1 y agregamos un 0 si el número es de un solo dígito
    // const dia = fechaActual.getDate().toString().padStart(2, "0");
    const fechaActualMostrar = moment().format(formatoFechaBD);
    const unMesDespues = moment().add(1, 'months');



    
  return {
    formatFecha,
    formatInsertFecha,
    formatShowFecha,
    formatShowDefaultFecha,
    fechaActualMostrar,
    formatEditFecha,
    primerDiaMes,
    formatprimerDiaMes,
    unMesDespues
  };
}