import moment from 'moment/moment';
import { useEffect } from 'react';
import { useState } from 'react'

export const useNumero = () => {

  const [VariableNumerica,setVariableNumerica]  = useState(0);

  const ConvertirVariable = (variable) => {

    const numero = Number(variable)

    setVariableNumerica(numero);

  }

  return [ VariableNumerica, ConvertirVariable];
}