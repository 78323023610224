import axios from 'axios';
import React, { useState } from 'react'

const useSelect= () => {

    const [objetoSelect, setObjetoSelect] = useState([]);

    const ConvertirAObjeto = async (objeto) => {

      
//       const nuevoObjeto = objeto.map(propiedad => {
//         const objetoActualizado = { "id": id, "descripcion": descripcion };
//         return objetoActualizado;
//         // if(propiedad.id === objetoActualizado) {
//         //   return objetoActualizado;
//         // }
//         // return propiedad
//       });

//       console.log(nuevoObjeto);
//       setObjetoSelect(nuevoObjeto);

//         //Hacemos una copia del objeto original
//         //const copiaObjeto = {...objetoSelect};

        
//         // objetoSelect.id = id;
//         // objetoSelect.descripcion = descripcion;
        
//         // console.log(copiaObjeto)

//         //cambiamos el valor de la copia del objeto

//         //setObjetoSelect(copiaObjeto;
    }

  return {
    ConvertirAObjeto,
    objetoSelect
  }
}

export default useSelect