import { useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Dashboard from "../Componentes/Dashboard";
import Footer from "../Componentes/Footer";
import Header from "../Componentes/Header";
import { useAuth } from "../Context/Context";
import { StyledPrivaterouter } from "../themes/StyledPrivaterouter";
import Loader from "../Componentes/Loader";
import MenuMovil from "../assets/svg/lista.svg";
import { useState } from "react";
import Swal from "sweetalert2";
import NoDisponible from "../Componentes/NoDisponible";

export function ProtectedRoute({ children }) {
  const { user, loading, setLoading } = useAuth();
  const [mostrarMenu, setMostrarMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);
    if(!user)
      navigate("/")
  }, []);

  return (
    <>
      {loading ?
          <StyledPrivaterouter>
            <div className="PositionLoader">
              <Loader />
            </div>
          </StyledPrivaterouter>
          :
          <StyledPrivaterouter>
            <div className="global">
              <div className="globalBodyApp">
                  <div className= {`sidebar ${mostrarMenu ? 'open' : 'close'}`}>
                    <Dashboard mostrarMenu={mostrarMenu} setMostrarMenu={setMostrarMenu} />
                  </div>
                  <div className="header">
                      <Header />
                    </div>
                <div className="appBody">
                  <div className="ContenedorMovilTop">
                    <div className="contenedorBtnMostrarMenu">
                      <button className="btnMostrarMenu" onClick={() => setMostrarMenu("mostrarMenu")}><img src={MenuMovil} alt="Menu" /></button>
                    </div>
                    
                  </div>
                  <div className="children">
                    <div className="childrenBody">
                      {children}
                    </div>
                  </div>
                </div>
                  <div className="footerPrincipal" >
                    <Footer />
                  </div>
              </div>
            </div>
            <div className="ContenedorComponenteNoDisponible">
              <NoDisponible />
            </div>
          </StyledPrivaterouter>
      }
    </>
  )

}