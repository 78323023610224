import React, { useContext, useEffect, useState } from 'react'
import { HashRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Dashboard from '../Componentes/Dashboard';
import Submenu from '../Componentes/Submenu';
import AgregarActividades from '../Pages/AgregarActividades';
import Home from '../Pages/Home';
import ListadoActividades from '../Pages/ListadoActividades';
import Loginpage from '../Pages/Login';
import SeguimientoActividades from '../Pages/SeguimientoActividades';
import { ProtectedRoute } from './Privaterouter';
import ListadoSeguimiento from '../Pages/ListadoSeguimiento';
import Soportes from '../Pages/Soportes';
import ReporteActividades from '../Pages/ReporteActividades';
import CatalagoProyectos from '../Pages/CatalagoProyectos';
import { useAuth } from '../Context/Context';
// import { useLocalStorage } from '../hooks/useLocalStorage';



function Navigation() {

  const { user } = useAuth();

// console.log(user);
  if(!user){
    <Loginpage />
  }

  return (
    <Router>
      {/* <RouteChangeHandler /> */}
      <Routes>
        <Route path="/" element={<Loginpage />} />

        {/* RUTAS PRIVADAS */}
        {/* <Route
            path="/"
            element={
                <Home />
            }
          /> */}
        <Route
          path="/Inicio"
          element={
            <>
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            </>
          }
        />

        <Route
          path="/listadoActividades"
          element={
            <>
              <ProtectedRoute>
                <ListadoActividades />
              </ProtectedRoute>
            </>
          }
        />

        <Route
          path="/agregarActividades"
          element={
            <>
              <ProtectedRoute>
                <AgregarActividades />
              </ProtectedRoute>
            </>
          }
        />

        <Route
          path="/seguimientoActvidades"
          element={
            <>
              <ProtectedRoute>
                <SeguimientoActividades />
              </ProtectedRoute>
            </>
          }
        />


        <Route
          path="/listadoSeguimiento/:id"
          element={
            <>
              <ProtectedRoute>
                <ListadoSeguimiento />
              </ProtectedRoute>
            </>
          }
        />


        <Route
          path="/soportes"
          element={
            <>
              <ProtectedRoute>
                <Soportes />
              </ProtectedRoute>
            </>
          }
        />

        <Route
          path="/ReportesActividades"
          element={
            <>
              <ProtectedRoute>
                <ReporteActividades />
              </ProtectedRoute>
            </>
          }
        />

        
        <Route
          path="/CatalogoProyectos"
          element={
            <>
              <ProtectedRoute>
                <CatalagoProyectos />
              </ProtectedRoute>
            </>
          }
        />

      </Routes>
    </Router>
  )
}

export default Navigation

// const RouteChangeHandler = () => {

//   const { cambioSeccion } =useLocalStorage();

//   const ContenedorRutas = useState([
//     {
//       id : 0,
//       descripcion : "/Inicio",
//       valor : "Inicio"
//     },
//     {
//       id : 1,
//       descripcion : "/listadoActividades",
//       valor  : "Bitacora"
//     },
//     {
//       id : 2,
//       descripcion : "/ReportesActividades",
//       valor : "Reportes"
//     },
//     {
//       id : 3,
//       descripcion : "/CatalogoProyectos",
//       valor : "Catalogo"
//     },
//     {
//       id : 4,
//       descripcion : "/",
//       valor : "Login"
//     }
// ])

//   const location = useLocation();
//   let localisacionEncontrada;

//   useEffect(() => {

//     for (let propiedad in ContenedorRutas) {
//       for (let index in ContenedorRutas[propiedad]) {
//         if(location.pathname === ContenedorRutas[propiedad][index].descripcion){
//           localisacionEncontrada = ContenedorRutas[propiedad][index].valor
//         }
//       }
//       }
//       cambioSeccion(localisacionEncontrada);
//   })
// }