import { useState } from 'react'
import axios from 'axios'
import { useAuth } from '../Context/Context';
import { useFecha } from './useFecha';

export const useShowDataTable = (url) => {

  const [data, setData] = useState(null);
  const [detailData, setDetailData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  //Llamada al hook para mostrar el usurio en linea
  const { user } = useAuth();

  const { fechaActualMostrar,formatFecha } = useFecha();



   //formulario de registro de actividades
   const [nombreUsuario, setNombreUsuario] = useState(user.nombreusuario);
   const [idusuario, setIdUsuario] = useState(user.idusuario);
   const [activityName, setActivityName] = useState("");
   const [activitydescription, setActivityDescription] = useState("");
   const [horas, setHoras] = useState("");
   const [estatus, setStatus] = useState(0);
   const [idActivity, setIdActivity] = useState(0);
   const [cambiarFecha, setCambiarFecha] = useState(fechaActualMostrar);
   const [actividadCancelada, setActividadCancelada] = useState(false);
   const [idProyectoSeleccionado, setIdProyectoSeleccionado] = useState(0);
   const [proyectoSeleccionado, setProyectoSeleccionado] = useState("Seleccionar proyecto...");

  const showData = async (url) => {
    setLoading(true);
    await axios.get(url)
    .then(response => {
        setLoading(false);
        setData(response.data.resultado);
        //console.log(response.data.resultado)
        
      })
      .catch(error => {
        setError(error);
      });
  }

  const showDetailsData = async (url) => {
    setLoading(true);
    await axios.get(url)
      .then(response => {
        setLoading(false);
        // console.log(response.data.nombreActividad);
        // setStatus(response.data.estatus);
        setActivityName(response.data.nombreActividad);
        setDetailData(response.data);
        console.log(response.data)
        let validarStatus = response.data.estatus;

        if(validarStatus === 4 ){
          setActividadCancelada(true);
        }

      })
      .catch(error => {
        setError(error);
      });
  }

  const showEditData = async(url) => {
      setLoading(true);
      await axios.get(url)
        .then(response => {
          setLoading(false);
          // console.log("E", response.data);
          setEditData(response.data);
          setIdUsuario(response.data.idUsuario);
          setNombreUsuario(response.data.nombreUsuario);
          setIdActivity(response.data.id);
          setActivityName(response.data.nombreActividad);
          setActivityDescription(response.data.descripcionActividad);
          setHoras(response.data.tiempo.minutosEstimados/60);
          setStatus(response.data.estatus);
          let ffecha = formatFecha(response.data.fechaInicio);
          setCambiarFecha(ffecha);
          setIdProyectoSeleccionado(response.data.idProyecto);
          setProyectoSeleccionado(response.data.nombreProyecto);
        })
        .catch(error => {
          setError(error);
        });
    }


  return {
    data,
    setData,
    detailData,
    editData,
    actividadCancelada,
    setActividadCancelada,
    idusuario,
    setIdUsuario,
    nombreUsuario,
    setNombreUsuario,
    idActivity,
    setIdActivity,
    activityName,
    setActivityName,
    activitydescription,
    setActivityDescription,
    horas, 
    setHoras,
    estatus,
    setStatus,
    cambiarFecha,
    setCambiarFecha,
    error,
    loading,
    setLoading,
    showData,
    showDetailsData,
    showEditData,
    idProyectoSeleccionado,
    setIdProyectoSeleccionado,
    proyectoSeleccionado,
    setProyectoSeleccionado
  };
}