import axios from 'axios';
import React, { useState } from 'react'
import { useAlert } from './useAlert';

const useCancelarProyectos = () => {

    const { alertSave, alertError } = useAlert();

    const [ProyectoCancelar, setPoryectoCancelar] = useState(false);

    // const CancelarProyectos = async (url, cancelar) => {
        
    //     await axios.put(url, cancelar)
    //     .then(response => {
    //       alertSave("Proyecto Cancelado");
    //     })
    //     .catch(error => {
    //       // console.log(error.request.response);
    //       alertError(error);
    //     });
    //   }

    const CancelarProyectos = async (url, cancelar) => {

      try{

        let RespuestaServicio =  await axios.put(url, cancelar);
        alertSave("Proyecto Cancelado");
      }catch(error){
        alertError(error);
      }

    }

  return {
    CancelarProyectos,
    ProyectoCancelar,
    setPoryectoCancelar
  }
}

export default useCancelarProyectos