import styled from 'styled-components';
 const StyledSelectPKT1 = styled.div`

.OpcionDesactivada{
   background: var(--BotonDesactivado);
}


.SelectOptions select{
    border: 2px solid rgb(102, 103, 112);
    border-radius: 4px;
    color: rgb(51, 51, 51);
    font-size: 16px;
    padding: 4px;
    width: 100%;
    outline: none;
    border-radius: 7px;
}

/* .SelectOpti{
    grid-area: Boton;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 4px 4px 0px;
    border-top: 2px solid rgb(102, 103, 112);
    border-right: 2px solid rgb(102, 103, 112);
    border-bottom: 2px solid rgb(102, 103, 112);
    border-image: initial;
    border-left: none;
} */

 .FaltaSeleccionar{
    color: var(--Rojo);
    font-weight: var(--Negritas);
    opacity: 1;
 }

 .Seleccionado{
   opacity: 0;
 }

 .NoSeleccionado select{
    border: 2px solid var(--Rojo);
    border-radius: 4px;
    color: rgb(51, 51, 51);
    font-size: 16px;
    padding: 4px;
    width: 100%;
    outline: none;
    border-radius: 7px;
 }

 .LabelNoSeleccionado{
    color: var(--Rojo);
 }
`

export {StyledSelectPKT1}