import axios from "axios"
import { useState } from "react";
import Swal from "sweetalert2"

const useDescargarArchivos = () => {

    const [loading, setLoading] = useState(false);

    const DescargarArchivos = async (url) => {

        setLoading(true)
        // console.log(url)
        await axios.get(url)
            .then(res => {
                if (res.status === 204) {
                    setLoading(false)
                    Swal.fire({
                        title: 'Error al descargar reporte',
                        text: 'No se encontraron movimientos del cliente',
                        icon: 'error',
                        position: 'top',
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: '#52C150'
                    })
                } else {
                    setLoading(false)
                    // const url = window.URL.createObjectURL(new Blob([res.data]))
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = "Actividades.xlsx";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    Swal.fire({
                        title: 'El reporte ha sido descargado',
                        icon: 'success',
                        position: 'top',
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: '#52C150'
                    })
                }
            })
            .catch(err => {
                setLoading(false)
                Swal.fire({
                    title: `No existen actividades dentro de este rango de fechas`,
                    text: `${err.response.data.message}`,
                    icon: 'info',
                    position: 'top',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#52C150'
                })

            })

    }

    return{
        loading,
        DescargarArchivos
    }

}

export default useDescargarArchivos