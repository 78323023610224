import styled from 'styled-components';
const StyledRegistro = styled.div`

.divRegistro{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 11rem;
}

.registro{
    display: flex; 
    justify-content: space-between;
    border: #CCCCCC 2px solid;
    border-radius: 4px;
    width: 5rem;
    background: #FFF;
    grid-template-columns: 1fr 20px;
    position: relative;
}

.contenidoRegistro{
    margin: 0 auto;
}

.descripcionRegistro{
    font-size: small;
    text-align: center;
    grid-column: 1;
}

.btnRegistro{
    background-color: #9597A7;
    border-radius:2px;
    width: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: #CCCCCC 2px solid;
    grid-column: 3;
}

.btnRegistro img{
    width: 1rem;
}

.ListadoOpcionesRegistro{
    position: absolute;
    display: block;
    background-color: #DBDDED;
    padding: 5px;
    width: 4.5rem;
    border-radius: 5px;
    font-size: small;
    box-shadow: 10px 10px 16px 0px rgba(0,0,0,0.25);
    line-height: 1rem;
    top: -7.5rem
}

.SelectOpcionesRegistro p{
    margin:0;
}

.SelectOpcionesRegistro{
    padding: 10px;
}

.SelectOpcionesRegistro:hover{
    background-color: #9597A7;
    color: #FFF;
    cursor: pointer;
    border-radius: 4px;
    padding: 10px;

}

@media only screen and (min-width: 300px){
    .divRegistro label{
        display: none;
    }

    .divRegistro{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 4rem;
    }
}

@media only screen and (min-width: 1200px){
    .divRegistro label{
        display: flex;
    }

    .divRegistro{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 10rem;
    }
}

`

export { StyledRegistro }