import styled from 'styled-components';
export const StyledReporteActividades = styled.div`

.CuerpoFormularioReporteActividades{
    display: flex;
    justify-content: center;
    position: relative;
    height: 85vh;
    align-items: center;

}

.FormularioReportes{
    text-align: center;
}

.secciones{
    display: flex;
    text-align: center;
    flex-direction: column;
    line-height: 3;
}

.secciones input{
    width: 20rem;
    margin: 0 auto;
    padding: 10px;
    border-radius: 10px;
}

.botonAccion{
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.botonAccion button{
    padding: 10px;
    border-radius: 10px;
    border: none;
    background: #52C150;
    color: white;
    cursor: pointer;

}



.divSelect{
    margin-top: 14px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.select{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: #CCCCCC 2px solid;
    height: 2.5rem;
    border-radius: 6px;
    width: 12rem;
    background: #FFF;
    position: relative;

}

.contenidoSelect{
    margin-left:1rem;
}

.descripcionSelect{
    font-size: small;
}

.btnSelect{
    background-color: #9597A7;
    height: 2.5rem;
    border-radius: 4px;
    width: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: #CCCCCC 2px solid;
    border-right: none;
}

.btnSelect img{
    width: 1rem;
}

.backOpciones{
    grid-column: 7 / 7;
}

.ListadoOpciones{
    position: absolute;
    background-color: #DBDDED;
    padding: 5px;
    width: 11.5rem;
    border-radius: 5px;
    font-size: small;
    box-shadow: 10px 10px 16px 0px rgba(0,0,0,0.25);
    line-height: 1rem;
    z-index: 1;
}

#ListadoSeguimiento{
    margin-left: -5rem;
}

.estatusOpciones{
    top: 2.5rem;
}

.SelectOpciones p{
    margin:0;
}

.SelectOpciones{
    padding: 10px;
}

.SelectOpciones:hover{
    background-color: #9597A7;
    color: #FFF;
    cursor: pointer;
    border-radius: 4px;
    padding: 10px;

}

.ContenedorFormularioReportes{
    display: flex;
    flex-direction: column-reverse;
    padding: 2rem;
    gap: 2rem;
}

.ContenedorHeaderReportes{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
}

.ContenedorFechasReportes{
    display: flex;
    justify-content: space-between;
    width: 30vw;
    flex-wrap: wrap;
    gap: 1rem;
}




`