import React from 'react'
import { StyledFooter } from '../themes/StyledFooter'

const Footer = () => {
  return (
    <StyledFooter>
      <div className='posicionFooter'>
        <div className='footerText'>
          <p>Desarrollado por PKT1 MX</p>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer