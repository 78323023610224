import React, { useState } from 'react'
import flechaInicio from '../assets/svg/puntas-de-flecha-de-contorno-delgado-a-la-izquierda.svg';
import flechaAnterior from '../assets/svg/flecha-izquierda.svg';
import flechaSiguiente from '../assets/svg/flecha-correcta.svg';
import flechaFin from '../assets/svg/simbolo-de-doble-flecha-derecha-de-avance-rapido.svg';
import { StyledPaginador } from '../themes/StyledPaginador';

const Paginador = (props) => {

    return (
        <StyledPaginador>
            <div className='paginador'>
                <div className={props.paginaActual > 1 ?'flechaInicio fondoStandar' : "PaginadorDesactivado  fondoStandar"} onClick={props.firstPage} style={props.disabledInicio ? { backgroundColor: 'gray', opacity: 0.5 } : null}>
                    <img src={flechaInicio} alt="flechaInicio" />
                </div>
                <div className={props.paginaActual > 1 ? 'flechaAnterior fondoStandar flechaCon' : "PaginadorDesactivado  fondoStandar" } onClick={props.prevPagina} style={props.disabledPrev ? { backgroundColor: 'gray', opacity: 0.5 } : null}>
                    <img src={flechaAnterior} alt="flechaAnterior" />
                </div>
                <div className='paginas'>
                    <p>{props.paginaActual}</p>
                    <p>/</p>
                    <p>{props.ultimaPagina}</p>
                </div>
                <div className= { props.paginaActual != props.ultimaPagina ? 'flechaSiguiente fondoStandar flechaCon' : "PaginadorDesactivado  fondoStandar" } onClick={props.next} style={props.disabledNext ? { backgroundColor: 'gray', opacity: 0.5 } : null}>
                    <img src={flechaSiguiente} alt="flechaSiguiente" />
                </div>
                <div className={ props.paginaActual != props.ultimaPagina ? 'flechaFinal fondoStandar' : "PaginadorDesactivado  fondoStandar"} onClick={props.lastPage} style={props.disabledFin ? { backgroundColor: 'gray', opacity: 0.5 } : null} disabled>
                    <img src={flechaFin} alt="flechaFinal" />
                </div>
            </div>
        </StyledPaginador>
    )
}

export default Paginador